import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  faWhatsapp,
  faTelegramPlane,
} from '@fortawesome/free-brands-svg-icons';
import './../styles/contacts.css';
import { feedbackSubmit } from '../api/post';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FeedbackForm from './utils/FeedBackForm';
import PopupMessage from './utils/PopupMessage';
import useFormSubmission from './hooks/useFormSubmission';
import { Helmet } from 'react-helmet';
import ufaMap from './../img/ufa-map.png';
import istanbulMap from './../img/istanbul-map.png';

const Contacts = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  // Состояние для всплывающего сообщения
  const { isSuccess, isError, isMessageHidden, errorMessage, onSubmit } =
    useFormSubmission(
      feedbackSubmit,
      t('flash.succesSend'),
      t('flash.error'),
      reset,
    );

  const fields = useMemo(
    () => [
      {
        type: 'text',
        id: 'email',
        placeholder: t('contacts.emailPlaceholder'),
        required: true,
      },
      {
        type: 'text',
        id: 'name',
        placeholder: t('contacts.namePlaceholder'),
        required: true,
      },
      {
        type: 'textarea',
        id: 'subject',
        placeholder: t('contacts.subjectPlaceholder'),
        required: true,
      },
      {
        type: 'textarea',
        id: 'message',
        placeholder: t('contacts.messagePlaceholder'),
        required: true,
      },
    ],
    [t],
  );

  return (
    <div className='contacts-wrapper'>
      <Helmet>
        <title>{t('SEO.contactsPageTitle')}</title>
        <meta name='description' content={t('SEO.contactsPageDescription')} />
        <link rel='canonical' href={`${t('SEO.url')}contacts`} />
        <meta property='og:title' content={t('SEO.contactsPageTitle')} />
        <meta
          property='og:description'
          content={t('SEO.contactsPageDescription')}
        />
      </Helmet>
      <div className='left-sidebar-background-img'></div>
      <div className='contact-section'>
        <div className='container-name'>
          <FontAwesomeIcon
            icon={faAddressCard}
            className='section-title-icon'
          />
          <h1>{t('contacts.sectionTitleContacts')}</h1>
        </div>
        <div className='flex-container'>
          <div className='contact-info'>
            <div className='contact-item'>
              <div className='contact-info-container'>
                <div className='info'>
                  <div>
                    <i className='fas fa-phone contact-icon'></i>{' '}
                    {t('contacts.contactRussia')}
                    <a href='tel:+79870275033'>+7(987)0275033</a>
                  </div>
                  <div className='address-item'>
                    <i className='fas fa-map-marker-alt contact-icon'></i>{' '}
                    {t('contacts.addressUfa')}
                  </div>
                </div>
                <div className='map'>
                  <a
                    href='https://www.google.com/maps?q=54.750369599020495, 56.03222874973753'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={ufaMap} alt='Карта Уфы' width={20} height={20}/>
                  </a>
                </div>
              </div>
            </div>
            <div className='contact-item'>
              <div className='contact-info-container'>
                <div className='info'>
                  <div>
                    <i className='fas fa-phone contact-icon'></i>{' '}
                    {t('contacts.contactTurkey')}
                    <a href='tel:+905319711791'>+90(531)9711791</a>
                  </div>
                  <div className='address-item'>
                    <i className='fas fa-map-marker-alt contact-icon'></i>
                    {t('contacts.addressIstanbul')}
                  </div>
                </div>
                <div className='map'>
                  <a
                    href='https://www.google.com/maps?q=41.01571218360771, 28.90691170610249'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={istanbulMap} alt='Карта Стамбула' width={20} height={20}/>
                  </a>
                </div>
              </div>
            </div>
            <div className='contact-item'>
              <div>
                <i className='far fa-envelope-open contact-icon'></i>
                <a href='mailto:dimmarexchange@gmail.com'>
                  dimmarexchange@gmail.com
                </a>
              </div>
            </div>

            <div className='info'>
              <FontAwesomeIcon
                icon={faTelegramPlane}
                className='contact-icon'
              />
              <a
                href='https://t.me/DIMMAR_EX'
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('contacts.telegram')}
              </a>
            </div>

            <div className='contact-item'>
              <div className='info'>
                <FontAwesomeIcon icon={faWhatsapp} className='contact-icon' />
                <a
                  href='https://wa.me/905319711791'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t('contacts.whatsapp')}
                </a>
              </div>
            </div>
            <div className='contact-item'>
              <div className='info'>
                <h2>{t('contacts.followUs')}</h2>
                <p></p>
                <div className='contact-info-container'>
                  <div className='contact-item'>
                    <div className='info'>
                      <FontAwesomeIcon
                        icon={faTelegramPlane}
                        className='contact-icon'
                      />
                      <a
                        href='https://t.me/Dimmar_exchange_Turkey'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {t('contacts.ourTelegramChannel')}
                      </a>
                    </div>
                    <div className='info'>
                      <FontAwesomeIcon
                        icon={faTelegramPlane}
                        className='contact-icon'
                      />
                      <a
                        href='https://t.me/DimmarExBot'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {t('contacts.ourTelegramBot')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Обратная связь */}
      <div className='contact-section form-section'>
        <div className='container-name form-container-name'>
          <FontAwesomeIcon icon={faEnvelope} className='section-title-icon' />
          {t('contacts.sectionTitleFeedback')}
        </div>
        <div className='flex-container flex-form-container'>
          <div className='contact-form'>
            {/* Всплывающее сообщение */}
            <PopupMessage
              isSuccess={isSuccess}
              isError={isError}
              hideMessage={isMessageHidden}
              successMessage={t('flash.succesSend')}
              errorMessage={errorMessage || t('flash.error')}
            />
            <FeedbackForm
              fields={fields}
              submitLabel={t('contacts.submitLabel')}
              onFormSubmit={onSubmit}
              register={register}
              errors={errors}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
      <div className='right-sidebar-background-img'></div>
    </div>
  );
};

export default Contacts;
