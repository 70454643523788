import './styles/city.css';
import locationImg from './../../img/location-mark.svg';
import { useTranslation } from 'react-i18next';
import { CustomNavLink } from '../navbar/Navbar';
import Masonry from 'react-masonry-css';

const Cities = ({ countries }) => {
  const { t } = useTranslation();

  // Отдельные массивы для России и других стран
  const russianCountry = countries.find((country) => country.name === 'Russia');
  const otherCountries = countries
    .filter((country) => country.name !== 'Russia')
    .sort((a, b) => {
      const countryA = t(`countries.${a.name}`);
      const countryB = t(`countries.${b.name}`);
      return countryA.localeCompare(countryB);
    });

  const breakpointColumnsObj = {
    default: 5,
    1100: 3,
    700: 1,
  };

  return (
    <div className='cities-wrapper'>
      <div className='cities-container'>
        <div className='cities-container__title'>
          <h2 className='block-title-h2'>{t('main.citiesTitle')}</h2>
        </div>
        
        <div className='row-countries'>
        {/* Левая колонка для России */}
        {russianCountry && (
          <div className='russian-column'>
            <h3 className='с-title'>{t('countries.Russia')}</h3>
            <div className='cities-list'>
              {russianCountry.cities
                .filter((city) => t(`cryptoCity.${city.name}.baseCity`) !== `cryptoCity.${city.name}.baseCity`)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((city) => (
                  <div key={city.id} className='city-item'>
                    <img src={locationImg} alt='Location' width='28' height='28' />
                    <CustomNavLink to={`/usdt/${city.name}`} rel='noopener noreferrer'>
                      {t(`cryptoCity.${city.name}.baseCity`)}
                    </CustomNavLink>
                  </div>
                ))}
            </div>
          </div>
        )}

        {/* Masonry для остальных стран */}
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className='masonry-grid'
          columnClassName='masonry-grid_column'
        >
          {otherCountries.map((country) => {
            const countryName = t(`countries.${country.name}`);
            if (countryName === `countries.${country.name}`) return null;

            return (
              <div key={country.name} className='country-block'>
                <h3 className='с-title'>{countryName}</h3>
                <div className='cities-list'>
                  {country.cities
                    .filter((city) => t(`cryptoCity.${city.name}.baseCity`) !== `cryptoCity.${city.name}.baseCity`)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((city) => (
                      <div key={city.id} className='city-item'>
                        <img src={locationImg} alt='Location' width='28' height='28' />
                        <CustomNavLink to={`/usdt/${city.name}`} rel='noopener noreferrer'>
                          {t(`cryptoCity.${city.name}.baseCity`)}
                        </CustomNavLink>
                      </div>
                    ))}
                </div>
              </div>
            );
          })}
        </Masonry>
        </div>
      </div>
    </div>
  );
};

export default Cities;