import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './styles/exchangeDetails.css';
import './styles/welcomeSection.css';
import './../../styles/contacts.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWhatsapp,
  faTelegramPlane,
} from '@fortawesome/free-brands-svg-icons';

function ExchangeDetails() {
  const { exchangeId } = useParams();
  const { t } = useTranslation();

  return (
    <div className='exchange-details-container'>
      <h1 className='container-name'>
        {t('exchangeDetails.exhchangeId')} {exchangeId}
      </h1>
      <div className='flex-container'>
        <div className='contact-info exchange-details'>
          <p className='exchange-details-text'>
          {t('exchangeDetails.mailDuplicated')}
          </p>
          <p className='exchange-details-text'>{t('exchangeDetails.requiredText')}</p>
          <p className='exchange-details-text'><strong>{t('exchangeDetails.attention')}</strong></p>
          <p className='exchange-details-text'>
            {t('exchangeDetails.crediPay')}
          </p>
          <p className='exchange-details-text'>
            <strong>{t('exchangeDetails.important')}</strong>
          </p>
          <p className='exchange-details-text'>
            {t('exchangeDetails.importantNote')}
          </p>
          <h2 className='exchange-details-main-text'>
            {t('exchangeDetails.h2Title')}
          </h2>
          <div className='button-container'>
            <a
              href='https://t.me/DIMMAR_EX'
              target='_blank'
              rel='noopener noreferrer'
              className='telegram-button'
            >
              <FontAwesomeIcon
                icon={faTelegramPlane}
                className='contact-icon'
              />
              {t('contacts.telegram')}
            </a>
          </div>
          <div className='button-container'>
            <a
              href='https://wa.me/905319711791'
              target='_blank'
              rel='noopener noreferrer'
              className='telegram-button'
            >
              {' '}
              <FontAwesomeIcon icon={faWhatsapp} className='contact-icon' />
              {t('contacts.whatsapp')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExchangeDetails;
