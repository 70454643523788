import { useContext } from 'react';
import { Context } from '..';
import { publicRoutes, commonRoutes } from './public';
import { protectedRoutes } from './protected';
import { observer } from 'mobx-react-lite';
import { Navigate, Route, Routes } from 'react-router-dom';
import { DASHBOARD, SIGN_IN } from './paths';
import LanguageWrapper from '../feature/LanguageWrapper';
import useCurrentLanguage from '../components/hooks/useCurrentLanguage,';

// import useLanguage from '../components/hooks/useLanguage';
// import Navbar from '../components/navbar/Navbar';

const ProtectedRoute = observer(({ element }) => {
  const { store } = useContext(Context);

  const lng = useCurrentLanguage();
  const defaultLanguage = 'ru';
  const languagePrefix = lng !== defaultLanguage ? `/${lng}` : '';
  const navigatePath = `/${languagePrefix}/${SIGN_IN}`.replace(/\/+/g, '/');
  return store.isAuth ? element : <Navigate to={navigatePath} replace />;
});

const PublicRoute = observer(({ element }) => {
  const { store } = useContext(Context);
  return store.isAuth ? <Navigate to={`/${DASHBOARD}`} replace /> : element;
});

const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/:lng?/*' element={<LanguageWrapper />}>
        {protectedRoutes.map((route, index) => (
          <Route
            key={`protected-${index}`}
            path={route.path}
            element={<ProtectedRoute element={route.element} />}
          >
            {route.children &&
              route.children.map((childRoute, childIndex) => (
                <Route
                  key={childIndex}
                  index={childRoute.index}
                  path={childRoute.path}
                  element={<ProtectedRoute element={childRoute.element} />}
                />
              ))}
          </Route>
        ))}
        {publicRoutes.map((route, index) => (
          <Route
            key={`public-${index}`}
            path={route.path}
            element={<PublicRoute element={route.element} />}
          />
        ))}
        {commonRoutes.map((route, index) => (
          <Route
            key={`common-${index}`}
            path={route.path}
            element={route.element}
          />
        ))}
      </Route>
    </Routes>
  );
};

export default observer(AppRoutes);
