export const SIGN_IN = 'sign-in';
export const SIGN_UP = 'sign-up';
export const PASSWORD_RESET = 'password-reset';
export const PASSWORD_RESET_SUCCESS = 'password-reset/success';

export const DASHBOARD = 'auth/dashboard';

export const PASSWORD = 'password';
export const STATISTIC = 'statistic';
export const BALANCE = 'balance';
// export const ADVERTISING = '/auth/dashboard/advertising';

