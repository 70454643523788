import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faKey,
  faChartBar,
  faWallet,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons'; // импортируем иконки
import { observer } from 'mobx-react-lite';
import { CustomNavLink } from '../../components/navbar/Navbar';
import './style/style.css';
import { PASSWORD, STATISTIC, BALANCE, DASHBOARD } from '../../routes/paths';
import { useContext } from 'react';
import { Context } from '../..';

const DashBoardSideBar = () => {
  const { t } = useTranslation();
  const { store } = useContext(Context);

  const handleGoOut = async () => {
    await store.logout();
    // navigate('/');
  };

  return (
    <div className='bar-container'>
      <ul>
        <li>
          <CustomNavLink to={DASHBOARD} end>
            <FontAwesomeIcon icon={faUser} /> {t('dashboard.userData')}
          </CustomNavLink>
        </li>
        <li>
          <NavLink to={PASSWORD}>
            <FontAwesomeIcon icon={faKey} /> {t('dashboard.password')}
          </NavLink>
        </li>
        <li>
          <NavLink to={STATISTIC}>
            <FontAwesomeIcon icon={faChartBar} /> {t('dashboard.statistic')}
          </NavLink>
        </li>
        <li>
          <NavLink to={BALANCE}>
            <FontAwesomeIcon icon={faWallet} /> {t('dashboard.partner')}
          </NavLink>
        </li>
      </ul>

      <button onClick={handleGoOut}>
        <FontAwesomeIcon icon={faSignOutAlt} /> {t('dashboard.exit')}
      </button>
    </div>
  );
};

export default observer(DashBoardSideBar);
