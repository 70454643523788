// components/FAQ.js

import { useTranslation } from 'react-i18next';
import locationImg from './../img/location-mark.svg';
import { Helmet } from 'react-helmet';

import { useEffect, useState } from 'react';
import { fetchCountries } from '../api/get';
import './../styles/siteMap.css';
import { CustomNavLink } from './navbar/Navbar';

const SiteMap = () => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const getCountries = async () => {
      const response = await fetchCountries();
      if (response.success) {
        setCountries(response.data);
      }
    };
    getCountries();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='rules-container'>
      <Helmet>
        <title>{t('SEO.siteMapTitle')}</title>
        <meta name='description' content={t('SEO.siteMapDescription')} />
        <link rel='canonical' href={`https://dimmarex.com/site-map`} />
        <meta property='og:title' content={t('SEO.siteMapTitle')} />
        <meta
          property='og:description'
          content={t('SEO.siteMapDescription')}
        />
      </Helmet>
      <div className='left-sidebar-background-img'></div>
      <div className='rules-wrapper'>
        <div className='section-title-container'>
          <h1>
            <div dangerouslySetInnerHTML={{ __html: t('siteMap.title') }}></div>
          </h1>
        </div>
        <div className='site-map-content-container'>
          {countries.map((country) => (
            <div>
              <div className='country-title' key={country.id}>
                <h2>{t(`countries.${country.name}`)}</h2>
              </div>
              {country.cities.map((city) => (
                <div className='site-map-wrapper'>
                  <div className='city-site-map-container s-h'>
                    <img src={locationImg} alt='Location' width="28" height="28"/>
                    <h4>{t(`cryptoCity.${city.name}.baseCity`)}</h4>
                  </div>
                  <ul>
                    <li>
                      <CustomNavLink to={`/usdt/${city.name}`}>
                        {t('cryptoCity.getUsdt')}{' '}
                        {t(`cryptoCity.${city.name}.city`)}
                      </CustomNavLink>
                    </li>
                    <li>
                      <CustomNavLink to={`/bitcoin/${city.name}`}>
                        {t('cryptoCity.getBitcoin')}{' '}
                        {t(`cryptoCity.${city.name}.city`)}
                      </CustomNavLink>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          ))}
           <div className='country-title'>
                <h2>{t('siteMap.other')}</h2>
        </div>
        <div className='site-map-sections'>
                <ul>
                  <li>
                    <CustomNavLink to='/'>{t('navbar.home')}</CustomNavLink>
                  </li>
                  <li>
                    <CustomNavLink to='/kyc'>{t('navbar.kyc')}</CustomNavLink>
                  </li>
                  <li>
                    <CustomNavLink to='/reviews'>{t('navbar.reviews')}</CustomNavLink>
                  </li>
                  <li>
                    <CustomNavLink to='/contacts'>{t('navbar.contacts')}</CustomNavLink>
                  </li>
                  <li>
                    <CustomNavLink to='/rules'>{t('navbar.rules')}</CustomNavLink>
                  </li>
                  <li><CustomNavLink to="/partnership">{t('navbar.partnership')}</CustomNavLink></li>
                  <li><CustomNavLink to="/site-map">{t('navbar.siteMap')}</CustomNavLink></li>
                </ul>
        </div>
       
        </div>

      </div>
      <div className='right-sidebar-background-img'></div>
    </div>
  );
};

export default SiteMap;
