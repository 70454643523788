// routes/public.js
import Homepage from '../components/hompage/Homepage';
import KYC from '../components/KYC';
import Contacts from '../components/Contacts';
import Partnership from '../components/Partnership';
import Reviews from '../components/Reviews';
import SiteMap from '../components/SiteMap';
import Rules from '../components/Rules';
import Articles from '../components/articles/Articles';
import ArticleDetails from '../components/articles/ArticleDetails';
import LearningMaterials from '../components/articles/LearningMaterials';
import ExchangeDetails from '../components/hompage/ExchangeDetails';
import CityHompage from '../components/City';
import NotFound from '../components/NotFound';
import CryptoCity from '../components/CryptoCity';
import SigInPage from '../feature/auth/SignIn';
import { PASSWORD_RESET, PASSWORD_RESET_SUCCESS, SIGN_IN, SIGN_UP } from './paths';
import SignUpPage from '../feature/auth/SignUp';
import RessetPasswordPage, {ResetPasswordSuccessPage} from '../feature/auth/RessetPasswordPage';
import Politica from '../components/Politica';


export const publicRoutes = [
  { path: SIGN_IN, element: <SigInPage /> },
  { path: SIGN_UP, element: <SignUpPage /> },
  { path: PASSWORD_RESET, element: <RessetPasswordPage /> },
  { path: PASSWORD_RESET_SUCCESS, element: <ResetPasswordSuccessPage /> },
];


export const commonRoutes = [
  { path: 'exchange-details/:exchangeId', element: <ExchangeDetails /> },
  { path: 'bitcoin/:cityName/exchange/', element: <CryptoCity /> },
  { path: 'bitcoin/:cityName/', element: <CryptoCity /> },
  { path: 'usdt/:cityName/exchange/', element: <CityHompage /> },
  { path: 'usdt/:cityName/', element: <CityHompage /> },
  { path: 'exchange/', element: <Homepage /> },
  { path: '', element: <Homepage /> },
  { path: 'kyc', element: <KYC /> },
  { path: 'articles', element: <Articles /> },
  { path: 'learning-materials', element: <LearningMaterials /> },
  { path: 'article/:slug', element: <ArticleDetails /> },
  { path: 'reviews', element: <Reviews /> },
  { path: 'contacts', element: <Contacts /> },
  { path: 'partnership', element: <Partnership /> },
  { path: 'site-map', element: <SiteMap /> },
  { path: 'rules', element: <Rules /> },
  { path:'politica', element: <Politica />},
  { path: '*', element: <NotFound /> }
];
