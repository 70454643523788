import botImgWebp from '../../img/botImg.webp';
import botImgPng from '../../img/botImg.png';
import { useTranslation } from 'react-i18next';

const TgBotInfo = () => {
  const { t } = useTranslation();

  return (
    <div className='advantage-content m-0'>
      <h2
        className='block-title-h2'
        dangerouslySetInnerHTML={{ __html: t('advantage.botTitle') }}
      ></h2>
      <div className='bot-content'>
        <picture>
          <source srcSet={botImgWebp} type='image/webp' />
          <source srcSet={botImgPng} type='image/png' />
          <img src={botImgPng} alt='Dimmar Exchange Bot' className='bot-img' width="300" height="auto"/>
        </picture>
        <div className='bot-info-wrapper'>
          <div className='font-regular-24'>{t('advantage.botPossibilities')}</div>
          <ul className='bot-info-container'>
            <li>{t('advantage.li1')}</li>
            <li>{t('advantage.li2')}</li>
            <li>{t('advantage.li3')}</li>
            <li>{t('advantage.li4')}</li>
          </ul>
          <div className='button-container'>
            <a
              href='https://t.me/DimmarExBot'
              target='_blank'
              rel='noopener noreferrer'
              className='telegram-button bot-info-btn'
            >
              {t('advantage.goToBot')}
            </a>
          </div>
        </div>
      </div>

      <h3 className='block-title-h2 m-s'>
        {t('advantage.botTitle2')} <br />
        <span>{t('advantage.botTitle2span')}</span>
      </h3>
      <div className='bot-info-transfer'>
        <div className='bot-info-transfer-item'>
          <div className='bot-info-number-wrapper'>
            <div className='bot-info-number'>1</div>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: t('advantage.transferText1') }}
          ></div>
        </div>
        <div className='bot-info-transfer-item'>
          <div className='bot-info-number-wrapper'>
            <div className='bot-info-number'>2</div>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: t('advantage.transferText2') }}
          ></div>
        </div>
        <div className='bot-info-transfer-item'>
          <div className='bot-info-number-wrapper'>
            <div className='bot-info-number'>3</div>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: t('advantage.transferText3') }}
          ></div>
        </div>
      </div>
      <a
        href='https://t.me/Dimmar_exchange_Turkey'
        target='_blank'
        rel='noopener noreferrer'
        className='telegram-button mw-50'
      >
        {t('contacts.ourTelegramChannel')}
      </a>
    </div>
  );
};

export default TgBotInfo;
