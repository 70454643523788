// useCurrentLanguage.js
import { useLocation } from 'react-router-dom';

const useCurrentLanguage = () => {
  const location = useLocation();
  const availableLanguages = ['en', 'tr'];
  const defaultLanguage = 'ru';

  const pathParts = location.pathname.split('/').filter(Boolean);
  const lng = availableLanguages.includes(pathParts[0]) ? pathParts[0] : defaultLanguage;

  return lng;
};

export default useCurrentLanguage;