import styled from '@emotion/styled';

export const Form = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  gap: 1rem; // 16px -> 1rem
  width: 100%;
  min-width: 20rem
`;

export const Input = styled('input')`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px; 
  gap: 0.625rem;
  min-width: 20rem;
  height: 2.8125rem; // 45px -> 2.8125rem
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid ${props => props.error ? 'red' : '#8b7575'};
  &:focus {
    outline: none; /* Убирает стандартный синий ховер в Chrome */
    box-shadow: none; /* Убирает стандартный синий ховер в Firefox */
  }
`;

export const InputContainer = styled('div')`
  position: relative;
  gap: 2px;
`;

  
export const Error = styled('span')`
  color: red;
  font-size: 0.875rem;
  color: red;
  font-size: 0.75rem;
  display: block;
`;

export const Button = styled('button')`
    background-color: #8F77F0;
    width: 100%;
    color: #fff;
    border-radius: 8px; /* Скругление углов кнопки */
    padding: 10px 20px;
    font-size: 1rem; /* Размер шрифта кнопки */
    margin-top: 20px; /* Отступ сверху для кнопки */
    border: 1px solid #000;
    border-bottom: 4px solid #000;
    font-weight: 600;
    cursor: pointer; /* чтобы показать, что это кликабельный элемент */
    transition: background 0.3s ease;
    text-align: center;
    &:hover {
      background-color: rgba(157, 251, 254, 1);
      color: #000;
      text-decoration: none;
    } 
`;

export const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.875rem 1.875rem; // верхний и нижний отступы такие же, как и боковые
  gap: 2rem;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(30, 119, 182, 0.08);
  border: 2px solid #000000;
  border-radius: 0 0 10px 10px;
  border-bottom: 8px solid #000000;
`;


export const PrimaryLink = styled.div`
  font-family: 'SF Pro Display', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 17px;
  text-decoration-line: underline;
  color: #8F77F0;

  margin-top: 1rem;
`;

export const SecondaryLink = styled.div`
  font-family: 'SF Pro Display', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 17px;
  text-decoration-line: underline;
  color: #153C5B;
  opacity: 0.5;
  height: 17px;
  margin-bottom: 1rem;
`;

// Стили для иконки
export const IconWrapper = styled.div`
  position: absolute;
  right: 10px; // Отступ справа
  top: 50%; // Позиционирование по вертикали
  transform: translateY(-50%); // Центрирование по вертикали
  cursor: pointer;
`;

