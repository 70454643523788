import { useParams, useLocation, useNavigate, Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

export const AVAILABLE_LANGUAGES = [
  'en',
  'en-US',
  'en-GB',
  'tr',
  'tr-TR',
  'ru',
  'ru-RU',
];

export const DEFAULT_LANGUAGES = ['ru', 'ru-RU'];

export const DEFAULT_LANGUAGE = 'ru';

const LanguageWrapper = () => {
  const { lng } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    // const firstPathSegment = location.pathname.split('/')[1];
    // const isLanguage = AVAILABLE_LANGUAGES.includes(firstPathSegment);

    if (lng && !AVAILABLE_LANGUAGES.includes(lng)) {
      Cookies.set('i18next', DEFAULT_LANGUAGE, { expires: 365 });
      navigate('/not-found/404', { replace: true });
    } else if (!lng || !AVAILABLE_LANGUAGES.includes(lng)) {
      // No language in URL or invalid language
      const savedLanguage = Cookies.get('i18next') || DEFAULT_LANGUAGE;

      if (!DEFAULT_LANGUAGES.includes(savedLanguage)) {
        // Redirect to URL with language prefix
        const newPathname = `/${savedLanguage}${location.pathname}`.replace(
          /\/+/g,
          '/',
        );
        navigate(newPathname + location.search, { replace: true });
        return;
      } else {
        // Ensure i18n is set to default language
        if (i18n.language !== DEFAULT_LANGUAGE) {
          i18n.changeLanguage(DEFAULT_LANGUAGE);
        }
      }
    } else {
      // Language is in URL and valid
      if (i18n.language !== lng) {
        i18n.changeLanguage(lng);
        Cookies.set('i18next', lng, { expires: 365 });
      }
    }
  }, [lng, i18n, navigate, location.pathname, location.search]);

  return <Outlet />;
};

export default LanguageWrapper;
