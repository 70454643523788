import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import FormField from './FormField';
import { ClipLoader } from 'react-spinners';

import {
  AuthContainer,
  Form,
  Button,
  PrimaryLink,
  SecondaryLink,
} from './style/formStyle';
import './style/auth.css';
import { PASSWORD_RESET, SIGN_UP } from '../../routes/paths';
import { CustomNavLink } from '../../components/navbar/Navbar';

const LoginForm = () => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm();
  const { store } = useContext(Context);
  const navigate = useNavigate();
  const [flashMessage, setFlashMessage] = useState('');
  const [loginError, setLoginError] = useState('');

  useEffect(() => {
    const message = localStorage.getItem('flashMessage');
    if (message) {
      setFlashMessage(message);
      localStorage.removeItem('flashMessage');
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      await store.login(data.username, data.password);
      navigate('/');
    } catch (error) {
      if (error.response && [400, 401, 404].includes(error.response.status)) {
        setLoginError(t('auth.loginError400'));
      } else {
        console.log(error)

        setLoginError(t('auth.loginError'));

        console.log(loginError)
      }
    }
  };

  return (
    <div className='contact-section form-section'>
      <div className='container-name form-container-name black-bg'>
        <h1>{t('auth.in')}</h1>
      </div>

      <AuthContainer>
        {loginError && <div className='error-message'>{loginError}</div>}
        {flashMessage && <div className='flash-message'>{flashMessage}</div>}

        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormField
            name='username'
            control={control}
            rules={{ required: t('auth.usernameRequired') }}
            placeholder={t('auth.username')}
            type='text'
          />
          <FormField
            name='password'
            control={control}
            rules={{
              required: t('auth.passwordRequired'),
              minLength: {
                value: 8,
                message: t('auth.notValidPassword'),
              },
            }}
            placeholder={t('auth.password')}
            type='password'
          />
          <Button type='submit' disabled={store.isLoading}>
            {store.isLoading ? (
              <ClipLoader size={20} color={'#9DFBFE'} />
            ) : (
              t('auth.signInBtn')
            )}
          </Button>
        </Form>
        <div className='auth-link-section'>
          <CustomNavLink to={SIGN_UP}>
            <PrimaryLink>{t('auth.createAccount')}</PrimaryLink>
          </CustomNavLink>
          <CustomNavLink to={PASSWORD_RESET}>
            <SecondaryLink>{t('auth.passwordSos')}</SecondaryLink>
          </CustomNavLink>
        </div>
      </AuthContainer>
    </div>
  );
};

export default observer(LoginForm);
